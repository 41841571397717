#not-avai-main{
    display: flex;
    justify-content: center;
    align-items: center;
}

#not-available-sec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    container-name: not-avai-sec;
    container-type: inline-size;
}

#not-available-sec>h1{
    font-size: 48px;
    margin-bottom: 2rem;
    text-align: center;
}

#not-available-sec>div{
    margin: 0.5rem auto;
}

#not-available-sec>div>p{
    margin: 0px;
    text-align: center;
}

#redirect-links{
    padding: 2rem;
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

}

#back-to-home{
    background-color: var(--glow-color);
    color: var(--theme-color);
}

#timer{
    height: 36px;
    width: 36px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    margin: 20px auto;

    background-image: conic-gradient(
        var(--theme-color) calc(var(--timer-percent) * 360deg - 40deg), 
        transparent calc(var(--timer-percent) * 360deg)
    );
}

@container not-avai-sec ( width < 800px ){
    #not-available-sec>h1{
        font-size: 36px;
    }
}