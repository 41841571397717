html{
  font-size: 16px;
  font-weight: 500;
  /* scrollbar-color: #c2c2c2 #fff;
  scrollbar-width: thin; */
}


::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
  background: #fff;
  width: thin;
}


::-webkit-scrollbar-thumb {
  background: #c2c2c2; /* 滑块颜色 */
  border-radius: 10px; /* 圆角 */
  border: 2px solid #fff;
}

:root{
  --theme-color: #268dcb;
  --theme-hover: #7cbee7;
  --test: #ff9743;
  --theme-gradient: linear-gradient(#268dcb, #7cbee7);
  --theme-dim-color: #a9d4ec;
  --theme-dark: #ffffff;
  --theme-glass-color: #268dcb50;
  --button-color: #268dcb;
  --background-color: #fafafc;
  --background-gradient: linear-gradient(150deg, #06080E, #17283C, #06080E, #122133);
  --title: #1d1d1f;
  --text: #202022;
  --de-text: #6e6e75;
  --border-color: #c5c5c5;
  --glass-color: #ffffff10;
  --shadow-color: #00000010;
  --shadow-heavy-color: #00000030;
  --glow-color: #ffffff;
  --glow-dim-color: #ffffff50;
  --glow-sdim-color: #ffffff10;
  --dark-glass-color: #000000d0;
  --backdrop-color: #f5f5f7;
  --border: #E8EAED;
}

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  z-index: 2;
  font-family: "Outfit", sans-serif;
  white-space: pre-wrap;
  letter-spacing: 0.1rem;

  /* background-color: #FF000020; */
}


#root{
  box-sizing: content-box;
  position: relative;
}

body{
  box-sizing: content-box;
  min-height: 100dvh;
  /* background-color: var(--background-color); */
  /* background-image: url("./un077-gradient.jpg"); */
  color: var(--title);
  /* height: 100dvh; */
  width: 100dvw;
  /* background-image: var(--background-gradient); */
  /* background-attachment: fixed;
  background-size: 400%;
  animation: bg-anim 5s ease-in-out infinite alternate; */
  background-color: #f6fbff;
  /* background-color: #d8c0fa; */
  overflow-x: hidden;
}

main{
  min-height: 75dvh;
}


@keyframes bg-anim{
  0%  {background-position: left;}
  100%  {background-position: right;}
}

h1{
  font-size: 5rem;
  font-weight: 600;
  word-spacing: 0.5rem;
  color: #202040;
  position: relative;
}

h2{
  font-size: 3rem;
  word-spacing: 0.2rem;
  color: #404060;
  font-weight: 500;
  position: relative;
}

h3{
  font-size: 2rem;
  word-spacing: 0.3rem;
  font-weight: 500;
  color: #404060;
  position: relative;
}

h4{
  font-size: 1.8rem;
  font-weight: 400;
  word-spacing: 0.2rem;
  color: #505060;
  position: relative;
}

p{
  word-spacing: 0.1rem;
  line-height: 200%;
  margin-top: 1rem;
  color: #606070;
  font-weight: 400;
  position: relative;
}

canvas{
  touch-action: pan-y !important;
}


img{
  width: 100%;
}

a{
  text-decoration: none;
  color: #404060;
}
a:focus{
  color: inherit;
}

input, textarea{
  /* background-color: #e8f0fe; */
  border-style: none;
  box-shadow: 0px 2px 10px 5px var(--shadow-color) !important;
  border-radius: 0.5rem;
  color: var(--text);
  font-size: 1.2rem;
  padding: 1rem 2rem;
  min-height: 2.5rem;
  max-height: 5rem;
  transition: all 0.3s;
  opacity: 0.8;
}
textarea{
  font-style: italic;
  color: var(--background-color);
  padding-top: 1rem;
  width: 100% !important;
  min-height: 10rem;
}
input::placeholder{
  color: var(--background-color);
  opacity: 0.5;
}
/* input:invalid{
  box-shadow: 0px 0px 10px #ff0000;
  background-color: #ffcece;
  border: 2px solid var(--glow-color);
  color: #ff3939;
} */
input:focus,
textarea:focus{
  opacity: 1;
}
input,
input:focus,
textarea:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:valid{
  color: var(--text);
  
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  /* border: 1px solid var(--text); */
  box-shadow: 0px 2px 10px 5px var(--shadow-color) !important;
  appearance: none;
}

input[type="checkbox"], input[type="checkbox"][required], input[type="radio"]{
  background-color: var(--glow-color) !important;
  display: block;
  box-shadow: 0px 2px 4px 2px var(--shadow-color) !important;
  padding: 0rem;
  margin-right: 1rem;
  height: 80%;
  aspect-ratio: 1/1;  
  cursor: pointer;

  background-image: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
input[type="checkbox"]:checked, input[type="radio"]:checked{
  background-image: url(/public/pictures/check_24dp_FILL0_wght400_GRAD0_opsz24.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  box-shadow: 0px 2px 4px 2px var(--shadow-color) !important;
}
input[type="file"]{
  position: relative;
  background-color: transparent;
  box-shadow: none !important;
  border: none !important;
  margin: auto 0rem;
  padding: 0rem;
  color: var(--border-color);
  cursor: pointer;
}
input[type="file"]:valid,
input[type="file"]:focus{
  border: none !important;
  box-shadow: none !important;
  color: var(--text);
  text-shadow: 0px 0px 10px var(--theme-color);
}
input::file-selector-button{
  background-color: transparent;
  border-style: none;
  color: transparent;
  padding: 0.5rem 2rem;
  margin: 4px 1rem 4px 4px;
  border-radius: 10rem;
}
input[type="file"]::before{
  background-color: var(--theme-color);
  color: var(--glow-color);
  box-shadow: 0px 0px 8px 4px var(--shadow-color);
  border-radius: 10rem;
  content: "選擇圖片";
  position: absolute;
  top: 50%;
  padding: 0.5rem 1rem;
  margin: auto 4px;
  font-style: normal;
  transform: translate(0%, -50%);
}

input:disabled{
  opacity: 0.2;
}

button, .button{
  padding: 1rem 2rem;
  border-radius: 10rem;
  background-color: var(--button-color);
  border-style: none;
  color: var(--glow-color);
  box-shadow: 0px 2px 8px 4px var(--shadow-color);
  backdrop-filter: blur(10px);
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  cursor: pointer;

  text-decoration-line: none;
  font-weight: 400;

  transition: all 0.5s;
}
button:hover, .button:hover{
  background-color: var(--theme-hover);
  box-shadow: 0px 0px 4px var(--glow-color);
  transform: translate(0px, -4px);
}

legend{
  font-size: 1.8rem;
  padding: 0rem 2rem;
}

dialog{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 992px;
  height: 80%;
  overflow: hidden;
  overflow-y: scroll;
  display: none;
  gap: 1rem;
  background-color: var(--backdrop-color);
  backdrop-filter: blur(10px);
  border-style: none;
  border: 1px solid var(--text);
  border-radius: 12px;
  padding: 2rem;
  z-index: 150;

  scrollbar-color: #c2c2c2 #fff !important;
  scrollbar-width: 10px;
}
dialog[open]{
  display: grid;
}
dialog::backdrop {
  background-color: var(--dark-glass-color);
  backdrop-filter: blur(10px);
  z-index: 149;
}
.close-btn{
  background-color: var(--theme-color);
  display: flex;
  position: sticky;
  top: 0rem;
  margin-left: auto;
  max-width: 3rem;
  width: 10%;
  aspect-ratio: 1/1;
  padding: 0rem;
}
.close-btn> img{
  width: 80%;
  aspect-ratio: 1/1;
  object-fit: contain;
  object-position: center;
  margin: auto;
}

.pointer{
  cursor: pointer;
}

.link-wrapper{
  position: relative;
}
.link-sensor{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.no-style{
  list-style-type: none;
}

.reading-sec{
  max-width: 1024px;
  width: calc(100% - 2rem);
  margin: auto;
  padding: 1rem
}

.text-gradient{
  width: max-content;
  background: linear-gradient(20deg, #404cf7 0%, #268dcb 50%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* position: static; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .fade-sec > *{
    opacity: 0;
    transform: scale(0.8);
    animation: fade-in ease forwards, fade-out ease forwards;
    animation-timeline: view();
    animation-range: 20dvh 40dvh, 80dvh 90dvh;
  }

  @keyframes fade-in {
    to { 
      opacity: 1; 
      transform: scale(1);
    }
  }

  @keyframes fade-out {
    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }
} */

.card{
  background-color: var(--glass-color);
  padding: 1.5rem;
  border-radius: 1rem;
  backdrop-filter: blur(5px);
  box-shadow: 0px 2px 4px 0px var(--shadow-color);
}
/* @media (prefers-reduced-motion: no-preference) {
  .slide-in{
    transform: translateX( -50%);
    opacity: 0;
    animation: slide-in ease forwards, slide-out ease forwards;
    animation-timeline: view();
    animation-range: 10% 40%, 60% 90%;
  }

  @keyframes slide-in {
    to { 
      transform: translateX( 0%);
      opacity: 1; 
    }
  }

  @keyframes slide-out {
    to {
      transform: translateX( +50%);
      opacity: 0;
    }
  }
} */
@media (prefers-reduced-motion: no-preference){
  .js-scroll-anim {
    opacity: 0;
    transform: scale(0.7);
    transition: all 0.7s;
  }
  .js-scroll-anim.in-view {
    opacity: 1;
    transform: scale(1);
  }
}



.text-center{
  text-align: center;
}

.text-glow{
  text-shadow: 0px 0px 10px var(--theme-color);
}

.go-btn{
    text-shadow: 0px 0px 4px var(--glow-color);
    border: 1px solid transparent;
    width: max-content;
    margin: auto;
    margin-bottom: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: block;
}

@media (max-width: 992px) {
  html{
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  html{
    font-size: 16px;
  }h1{
    font-size: 2.5rem;
  }
  
  h2{
    font-size: 1.8rem;
    letter-spacing: 0.2rem;
  }
  
  h3{
    font-size: 1.5rem;
    letter-spacing: 0.3rem;
  }
  
  h4{
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
}
@media (max-width: 576px) {
  html{
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  html{
    font-size: 16px;
  }
}