.webgl-body{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0;
    min-height: 0;
    width: 100dvw;
    height: 100dvh;
    position: fixed;

}

.webgl-root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.webgl-earth-dot-wrapper{
    position: relative !important;
    transform: none !important;
    top: 0 !important;
    left: 0 !important;
    aspect-ratio: 1/1;
    margin: auto;
}